<template>
  <div class="about">
    <div class="title">
      <h1>About us</h1>
    </div>
    <div class="reservation">
      <div class="info">
        <h1>We are your best car rental company in the area</h1>
        <hr>
        <h3>Visited by thousands of rental car customers every month!</h3>
        <p>
          Is aliquip exd ea consequat duis lorem ipsum dolor sit amet consectetur dipis icing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          Ut enim ad minim veniam quis nostrud exercitation.
          Slamco laboris nisi ut aliquip ex ea comdo consequat uis aute irure dolor raeprehenderit voluptate velit esse cillum dolore eu fugiat nulla pariatur.
        </p>
        <button @click="$router.push('/')"><span class="icon-calendar-plus"></span>Make reservation now</button>
      </div>
      <div class="image"></div>
    </div>
    <div class="u-comments">
      <div class="head">
        <h1>User comments</h1>
        <hr>
      </div>
      <div class="comment" v-for="(c, i) in users_comments" :key="i">
        <div class="userdata">{{c.username}}</div>
        <p>{{c.comment}}</p>
      </div>
    </div>
    <div class="counter">
      <div>
        <span class="icon-heart"></span>
        <p><b>980</b><br>Happy clients</p>
      </div>
      <div>
        <span class="icon-car"></span>
        <p><b>11</b><br>Vehicles listed</p>
      </div>
      <div>
        <span class="icon-map-marker"></span>
        <p><b>3</b><br>Available locations</p>
      </div>
      <div>
        <span class="icon-calendar-check"></span>
        <p><b>899</b><br>Reservations made</p>
      </div>
    </div>
<!--    <div style="height: 5rem"></div>-->
    <div class="features">
      <h1>Company features</h1>
      <hr>
      <p>The small details makes the difference</p>
      <div class="cards">
        <FeatureCard
            :icon="'icon-star'"
            :title="'Over 14 years in business'"
            :text="'Lorem ipsum dolor sit amet, cons ectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim.'"
        ></FeatureCard>
        <FeatureCard
            :icon="'icon-wrench'"
            :title="'Well maintained cars'"
            :text="'Lorem ipsum dolor sit amet, cons ectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim.'"
        ></FeatureCard>
        <FeatureCard
            :icon="'icon-ticket'"
            :rotate="true"
            :title="'Affordable prices'"
            :text="'Lorem ipsum dolor sit amet, cons ectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim.'"
        ></FeatureCard>
      </div>
    </div>
  </div>
</template>

<script>
import FeatureCard from "../components/FeatureCard";
  export default {
    name: 'About',
    components: {
      FeatureCard
    },
    data() {
      return {
        users_comments: []
      }
    },
    created() {
      this.$store.dispatch('isReady').then(() => {
        this.users_comments = this.$store.getters.siteData.comments
      })
    }
  }
</script>

<style scoped src="../styles/about.css"></style>
