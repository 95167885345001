<template>
  <div class="f-card">
    <div class="font-icon" :class="[icon, {'rotated': rotate}]"></div>
    <h3>{{title}}</h3>
    <p>{{text}}</p>
  </div>
</template>

<script>
export default {
  name: "FeatureCard",
  props: {
    icon: {type: String, required: true},
    title: {type: String, required: true},
    text: {type: String, required: true},
    rotate: {type: Boolean, default: false}
  }
}
</script>

<style scoped>
  .f-card {
    display: flex;
    flex-direction: column;
    min-height: 10rem;
    max-width: 30%;
    font-family: "Raleway", sans-serif;
    padding: 50px 0;
  }
  .f-card .font-icon {
    margin: auto;
    color: #000000;
    font-size: 3.5rem;
  }
  h3 {
    color: rgb(255, 206, 7);
    font-weight: 300;
  }
  p {
    font-size: 0.8rem;
  }
  .rotated {
    transform: rotate(135deg);
  }
</style>